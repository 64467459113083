import React from "react"
import {graphql} from 'gatsby';


class HomePage extends React.Component{
  render() {
    const { data } = this.props;
    const marketingPosts = data.allMarkdownRemark.edges;
    return (
      <div>
        Hello world!
        {marketingPosts && marketingPosts.map((post, index) => (
          <div key="{post.frontmatter.title}">
             <div dangerouslySetInnerHTML={{__html:post.node.html}} />
          </div>
        ))}
      </div>
    );
 }
}

export default HomePage;

export const query = graphql`
  query HPQuery {
    allMarkdownRemark(filter: {frontmatter: {}}) {
      edges {
        node {
          frontmatter{
            title
          }
          html
        }
      }
    }
  }
`
